@media (max-width: 1200px) {
  .container{
    max-width: 1156px !important;
}
.btns{
  flex-direction: column;
}
/* .btns .btn2
{
  padding: 5px 5px;
  width: 96px !important;
} */
}

@media ( max-width: 1048px) {
  .radio-btn{
    align-items:start;
  }
}

/* Meadiam */
@media (max-width: 884px) {
  #basmati-btn{
    flex-direction: column !important;
  }
 
}
@media (max-width: 991px) {
  .btns{
    flex-direction:row;
    width: 100%;
  }

  #basmati-btn .brbtn1{
    margin-bottom: 10px;
 
  }
  #basmati-btn .second-btn{
    width: 80% !important;
  }
  
   /* basmati rice button */
}  
@media (max-width: 850px) {
  #second-img{
    left:0px;
  }
}
@media (max-width: 845px) {
  .btns{
    flex-direction:column;
  }
}
@media (max-width: 916px) {

  .single-line b{
    width: 75% !important;
  }
}
@media (max-width: 800px) and (min-width:640px) {
  #basmati-btn-last .brbtn2
  {
    width: 180px;
    margin-left: 0px;
    position: absolute;
    top: 0;
  }
  #inner-box-Product #basmati-btn-last .brbtn2{
    width: 180px;
    margin:auto;
    position: relative;
  }

}
@media (max-width: 800px) {
  .col-form-img{
    display: none;
  }
  .col-form{
    width: 100% !important;
  }
  .modal-dialog {
    max-width: 80% !important; 
  }
  #inner-box-form .main-box1{
    flex-direction: column;
  }
  .modal-body {
    max-height: 400px !important;
    overflow-y: scroll;
  }
  #inner-box-form .inpute-name1
{
  display: flex;
  align-items: center;
  justify-content:start;
  margin-right: 10px;
  text-align:left;

}
  #inner-box-form{
    display: flex;
    flex-direction: column !important;
    width: 100% !important;
  }
  .col-form-img{
    max-width: 0% !important;
  }.col-form{
    padding-left: 25px !important;
    padding-right: 0px !important;
    margin-right: 0px !important;
    max-width: 100% !important;
  }
  #inner-box-form .box-btn{
    border-radius: 0px !important;
    margin-left: 0px !important;
  }
  #inner-form-full .textarea-box-text{
    width: 200px;
  }

}

/* Meadiam */
@media (max-width: 767px) {
  .btns{
    flex-direction:row;
  }
  #basmati-btn .second-btn{
    width: 100% !important;
  }

  table{
    border: solid gray 1px;
  }
  td{
    border: solid gray 1px;
    padding-left: 10px;
  }
  .first-text-product{
    padding-bottom: 25px;
  }
  .first-text-product2{
    padding: 0;
  }
  .head-product{
    padding-top: 15px;
  }

  .form-row{
    flex-direction: column;
  }
  .input-main-name-radio{
   justify-content: space-between;
   width: 100% !important;
  }
  .form-row-radio{
    width: 100% !important;
  }
.radio-btn{
  align-items: center;
}
#inner-box-form .main-box1{
  flex-direction: column;
}
#inner-box-form .inpute-name1
{
  justify-content:start;
  /* width: 320px; */
  margin-right: 10px;
  text-align:left;
}
#inner-box-form  #box-btn{
    justify-content: start;
}
.lookin-box{
  padding: 10px;
  margin: 0 30px;
}

#lookingfor h2,
.title-slide h2,
.inner-text b{
  font-size: 20px !important;
  font-weight: 700;
}
.title-slide h2

  /* basmati rice button */

#indianrice #basmati-btn-last .brbtn2
{
  width: 150px;
}
}
@media (max-width:665px) {
  .main-basmti-body{
    margin-left: 0px;
  }
}

/* Small  */

@media (max-width: 640px){
  .indian{
    display: flex !important;
    flex-direction: column !important;
  }
  .main-col{
    max-width: 100% !important;
  }
  .row-box
{
  display: flex;
  flex-direction: column;
}
.main-basmati-box{
  margin-top: 0px !important;
  margin-bottom: 0px;
  box-shadow: none;
  background-color: white;
  padding-top: 0px !important;
  border-bottom: 1px solid rgb(229, 224, 224);
}
.basmti-main-img{
   min-width: auto !important;
  padding: 0 0 !important;
}
.basmati-body{
 
  padding-bottom: 0px;
}
.main-basmti-body{
  margin: 0px 10px!important;

}
#basmati-table .table-box {
  padding: 20px 0px;
}
.basmati-body .title-box1{
  padding-top: 20px;
  
  font-size: 20px;
}
.main-line .gray-text,
.main-line b{
  width: 35%;
}
#basmati-btn{
  flex-direction: row !important;
}
.table-box-line{
  display: flex;
  flex-direction: column;
}
.tb td.gray-text {
  width: 40% !important;
  font-size: 14.7px;
}
.table-box{
  padding-bottom: 0px !important;
}
.single-line b{
  width: 60% !important;
}
#lookingfor .main-box1{
  flex-direction: column;
}
#lookingfor .inpute-name1
{
  justify-content:start;

  margin-right: 10px;
  text-align:left;
}
#lookingfor .textarea-box-text{
  width: 320px;
}
#bas .first-text-product2 {
  margin-left: 15px;
}
#box-btn{
  justify-content: start;
}

}




@media (max-width: 575px) {
.col-head{
  margin-top: 15px;
}
.first-text-product{
  margin-bottom: 15px;
 }

 .first-text-product2{
  padding-bottom: 15px;
}

#basmati-btn{
  flex-direction:row !important;
}
.modal.show .modal-dialog{
  margin: auto;
}


  
/* basmati rice button */

#indianrice #basmati-btn-last .brbtn2
{
  width: 200px;
}
}

@media (max-width: 480px){
  table
{
    border: none;
  }
  td{
    border: none;
    border-bottom: solid gray 1px;
    padding-left: 10px;
  }
  td .link{
    border-bottom: none !important;
  }
  .text-red{
    border-bottom: black 1px solid !important;
  }
  .modal-dialog {
    /* max-width: 80% !important; */
  }
  .tb td.gray-text{
    padding-left: 0px;
  }
  .link-view-more td{
    padding-left: 0px;
  }
  .basmati-body{
    padding-bottom: 20px !important;
  }

}


@media (max-width: 415px){
  .input-main-name-radio{
    flex-direction:column;
  }
  .radio-btn{
    width: 100% !important;
    margin-bottom: 5px;
  }
} 
@media (max-width: 393px) {
 

}
@media (max-width:425px) {
  #last-button {
    margin: 0px !important;
  }
  #last-button .bottom-btn{
    position: absolute;
    bottom: 0;
    padding: 0px;
    position: fixed;
    z-index: 11;
    width: 100%;
  }
  #last-button .brbtn2 {
    display: block;
    height: 51px !important;
    margin-bottom: 0 !important;
    font-size: 17.5px !important;
  }
  .row-box{
    flex-direction: column !important;
  }
  #basmati-btn{
    /* flex-direction: column !important; */
    display: none;
  }


}