.top-line {
    background-color: var(--color-nevy-blue);
    font-size: 14px;
    font-weight: bold;
}
/* .modal-dialog2{
    max-width: 600px !important;
    margin: auto;
} */

.top-line .top-line-space {
    padding: 10px 170px;
}

.f-div {
    color: rgb(209, 218, 225);
}

.top-line-btn .mail-btn,
.top-line-btn .sms-btn {
    border: none;
    padding: 3px 12px;
    border-radius: 15px;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
}

.top-line-btn .mail-btn {
    background-color: rgb(209, 218, 225);
}

.top-line-btn .sms-btn,
#contact-sec .btn-send {
    background-color: var(--color-cyan);
}

#contact-sec .btn-send {
    border: none;
    color: white;
    padding: 10px 20px !important;
}

.modal-footers button:hover,
#contact-sec .btn-send:hover {
    background-color: var(--color-nevy-blue);
}

.top-line-btn .btn1-send div div {
    padding: 10px 0;
    border: 1px solid rgb(176, 171, 171);
    margin: 10px 0;
    display: flex;
    border-radius: 10px;
    background-color: white;
}

.body-modal {
    position: relative;
    width: 100%;
    /* max-width: 340px; */
    border-radius: 20px;
    padding: 22px 10px;
}

.close-btn {
    position: absolute;
    top: -15px;
    right: -13px;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 6px;
    border-radius: 50%;
    border: solid 2px white;
}

.close-btn:hover {
    background-color: white;
    color: var(--color-dark-gray);
    border: 2px solid var(--color-dark-gray);
}

.btn-2 div div {
    margin: -1px !important;
}

.top-line-btn .btn1-send div div select {
    color: rgb(131, 133, 138);
}

.top-line-btn .btn1-send div div input,
.top-line-btn select,
.top-line-btn textarea {
    border: none;
    outline: none;

}

.top-line-btn .e-icon {
    margin: 0 10px;
    font-size: 19px;
    color: rgb(101, 97, 97);
}

.modal-footers button {
    padding: 15px 19px;
    color: rgb(228, 228, 228);
    border: none;
}

.bg-gray {
    background-color: rgb(206, 208, 209);
}

.title-modal h1 {
    color: rgb(96, 98, 99);
    font-weight: 600;
    height: 40px;
    font-size: 22px;
}
.top-line .flex {
    display: flex;
}
.t-div{
    position: absolute;
    background-color: black;
    width: 200px;
    height: 500px;
}

/* --------------------------------- About Name */
.about-sec {
    background-image: url('../img/asset\ 52.png');
    background-color: var(--color-cyan);
}

.about-sec .about-space {
    height: 90px;
    padding: 0px 170px;

}

.about-sec h2 {
    font-size: 24px;
}

.about-sec .mini-name {
    font-size: 12px;
    font-weight: 500;
}


/* {--------------------------- Start Description About us ------------------------------} */
.disc-about-sec {
    font-size: 13px;
    text-align: justify;
}

#main-about-dis {
    padding: 0px 190px;
    margin-top: 16px;
}


.a1-font p {
    margin-bottom: 20px;
}

.disc-about-sec .a1-font {
    padding-top: 5px;
}

.disc-about-sec .a1-font,
.con-div ul li {
    /* line-height: 22px; */
    font-weight: 500;
}

.disc-about-sec .a1-font ul li {
    line-height: 25px;
}

.disc-about-sec .a2-font ul li {
    line-height: 21px;
}

.disc-about-sec table tr th {
    padding: 10px;
    text-align: end;
    background-color: rgba(221, 222, 223, 0.5);
}

.disc-about-sec table tr td {
    padding-left: 10px;
}

.disc-about-sec table tr th,
.disc-about-sec table tr td {
    border: 1px solid rgba(172, 172, 172, 0.5);
}

.ct-margin {
    margin: 38px 0 62px 0;
}

/* ---------------------------------- */
.disc-about-sec .prod-div {
    border-bottom: 2px solid #008B8B;
    padding: 12px 12px 6px 10px;
}

.link-col ul {
    padding: 0;
}

.disc-about-sec .link-col {
    padding: 8px 10px;
}

.link-col ul li,
.con-div ul {
    list-style: none;
    padding: 0;
}


.prod-div h1 {
    font-size: 16px;
    font-weight: 700;
}

.prod-div h1 a:hover,
.link-col ul li a:hover,
.con-div ul li a:hover .p1-about a:hover {
    text-decoration: none;
}

.accord-p .link-col span a {
    color: rgb(118, 116, 116);
    font-weight: 600;
    line-height: 19.5px !important;
}

.disc-about-sec .link-col .ul-style {
    padding: 10px 0 !important;
    margin: 0;
    border-bottom: 0.1px solid rgba(142, 142, 142, 0.2);
}

.disc-about-sec .link-col .ul-style:last-child,
.con-div ul li:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.disc-about-sec .link-col .ul-style li a {
    color: var(--color-nevy-blue);
    font-weight: 700;
    line-height: 25.1px;
}

.disc-about-sec .link-col ul li ul li a {
    color: var(--color-light-gray) !important;
    font-weight: 500 !important;
}

.disc-about-sec .link-col ul li ul li a:hover {
    color: var(--color-dark-gray) !important;
}

.con-div ul {
    padding: 0px 12px;
}

.link-col b {
    color: var(--color-light-gray);
    margin-right: 3px;
}

.con-div ul li {
    text-align: left;
    border-bottom: 0.1px dashed rgba(142, 142, 142, 0.5);
    font-size: 13px;
    padding: 10px 0;
}

.mail-wrap {
    word-wrap: break-word;
}

/* --------------------------------------- Contact US */
#contact-sec {
    padding: 25px 170px 20px 170px;
}

#contact-sec,
#contact-sec .contact a {
    font-size: 14.5px;
    font-weight: 500;
    text-decoration: none;
    color: inherit;
    line-height: 19.5px;
}


#contact-sec .contact p {
    display: flex;
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.ms-31 {
    margin-left: 31px;
}

.ms-31 h1 {
    font-size: 1.1em;
    font-weight: bold;
    margin-bottom: 20px;
    text-decoration: solid 1px black;
}

#contact-sec .cont-icon {
    margin-right: 14px;
    font-size: 17px;
}

#contact-sec .cont-bg h1 {
    font-size: 20px;
    font-weight: 600;
}

#contact-sec .cont-bg {
    background-color: rgba(197, 201, 202, 0.3);
}

#contact-sec .cont-bg .row .row-2 {
    flex-direction: column;
}

#contact-sec .cont-bg .col1,
#contact-sec .cont-bg .col2 {
    width: 48%;
}

#contact-sec .pad {
    padding: 35px 45px 40px 48px;
}

#contact-sec .row input,
#contact-sec textarea,
#contact-sec select {
    padding: 10px 3px;
}

#contact-sec .cont-bg .row div {
    display: flex;
    background-color: white;
    margin: 5px 0px;
}

#contact-sec .div-border {
    padding: 0 0 0 5px;
    border: 1px solid rgba(195, 190, 190, 0.8);
}

#contact-sec .div-border input,
#contact-sec .div-border textarea,
#contact-sec .div-border select {
    width: 100%;
    border: none;
    outline: none;
}

#contact-sec .div-border input::placeholder,
#contact-sec .div-border textarea::placeholder,
#contact-sec .div-border select {
    font-size: 13px;
    color: var(--color-light-gray);
}

#contact-sec .div-border span {
    color: rgb(217, 31, 31);
    font-size: 19.5px;
    font-weight: 400;
}

.theme-bg {
    background-color: var(--color-cyan);
}

/* -----------------------sliders */
.sli .pad-div {
    width: 300px;
    padding: 10px;
}

.sli .img-w {
    background-color: rgba(239, 232, 232, 0.4);
    height: 200px;
}


/* ---------------------------------------------- Mini Footer */
#mini-footer {
    padding: 15px 184px 30px 184px;
}
#mini-footer .general-link a {
    margin: 0 5.5px;
    font-weight: bold;
}

.vn {
    color: rgba(255,255,255,0.7);
    font-size: 14.5px;
    font-weight: 700;
    display: flex;
    /* align-items: center; */
}

#mini-footer .general-link {
    font-weight: bold;
    line-height: 19.5px;
    overflow-wrap: break-word;
}
.country-img img{
    border: 1px solid rgba(255,255,255,0.5);
    margin-right: 5px;
    margin-bottom: 5px;
}
#mini-footer .row .row-3,
#mini-footer .row .row-3-1 {
    flex-direction: column;
}

#mini-footer .row-3 .col1{
    width: 40%;
}

#mini-footer .row-3 .col2 {
    width: 60%;
}

#mini-footer .row-3-1 .col1,
#mini-footer .row-3-1 .col2{
    width: 50%;
}
#mini-footer .footer-part-1 .row:first-child {
    margin-bottom:20px;
}
#mini-footer .t-center {
    font-size: 11.7px;
}

#mini-footer.footer-line {
    border: 1px dashed white;
}

.error {
    color: red;
}

.justify-end {
    display: flex;
    justify-content: end;
}

#mini-footer .footer-part-2 {
    padding-top: 12px;
    margin-top: 7px;
    border-top: 0.5px rgba(189, 186, 186, 0.7) dashed;
}